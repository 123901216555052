(function($) {
    $(document).ready(function($) {

        var toggle = $('#search_toggle'),
            wrap = $('.search_wrap'),
            input = $('#search_input'),
            submit = $('#search_submit');

        toggle.on('click', function(){
            $(this).fadeOut(0);
            wrap.addClass('open');
            input.focus();
        });

        $('#search-form').on( 'submit', function(e) {
            $.ajax({
                type: 'POST',
                url: ajax_search_object.ajaxurl,
                cache: false,
                data: {
                    'action': 'cia_search',
                    'query': $('#search-form #search_input').val(),
                    'security': ajax_search_object.nonce
                },
                beforeSend: function(){
                    toggle.fadeIn();
                    input.val('').blur();
                    wrap.removeClass('open');
                    submit.removeClass('fa-search').addClass('fa-cog fa-spin');
                    $('#cases').fadeOut('200');
                },
                success: function(data){
                    submit.removeClass('fa-cog fa-spin').addClass('fa-search');
                    $('#ajax_box').html(data);

                    //isotope grid
                    $grid = $('#cases');

                    $grid.imagesLoaded(function(){

                        $('.category-select').fadeIn('200');
                        $('.capability-select').fadeIn('200');

                        $grid.hide().fadeIn('200').isotope({
                          itemSelector: '.block',
                        });

                    });

                    var select = $('#category');
                    select.on('change', function(){
                      var option = ($(this).val() === '*') ? '*' : ('.' + $(this).val());
                      $grid.isotope({ filter: option });
                    });
                    var select2 = $('#capability');
                    select2.on('change', function(){
                      var option = ($(this).val() === '*') ? '*' : ('.' + $(this).val());
                      $grid.isotope({ filter: option });
                    });
                }
            });
            e.preventDefault();
        });
    });
})(jQuery);
